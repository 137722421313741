// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("local-time").start();
require("turbolinks").start();
import "bootstrap/dist/js/bootstrap";
import "jquery";
import "/tmp/build_3dbd6d3d/vendor/bundle/ruby/3.2.0/bundler/gems/axle_web-332d1e7bc47c/app/assets/javascripts/axle_web/header.coffee";
import '../data_links';
